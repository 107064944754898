@import url('https://fonts.googleapis.com/css2?family=Instrument+Sans:wght@500&display=swap&family=Poppins:wght@300;800;900&family=Rubik:wght@800&family=Sacramento&family=Source+Code+Pro:wght@300&display=swap');

*{
  scroll-behavior: smooth;
  cursor: url('../../public/images/pixelcursor.png');
}

body, html {
  overflow-x: hidden;
}

.main {
  text-align: left;
}

header {
  display: flex;
  position: fixed;
  top: -2%;
  left: 0;
  width: 100%;
  height: fit-content;
  padding: 5px 0px;
  justify-content: space-between;
  align-items: center;
  z-index: 1000000;
  user-select: none;
  transition: 0.5s ease-in-out;
}

body{
  width: 100%;
  overflow-x: hidden;
  margin: 0;
  justify-content: left;
  caret-color: transparent;
  cursor: url('../../public/images/pixelcursor.png'), auto;
}

.hidden {
  Opacity: 0;
  filter: blur(5px);
  transform: translateX(30%);
  transition: all 1s;
}

.fade {
  Opacity: 0;
  filter: blur(5px);
  transform: translateX(-30%);
  transition: all 1.5s;
}
  
.show{
  opacity: 1;
  filter: blur(0);
  transform: translateX(0);
}

.logo {
  height: 8vmin;
  width: 15hmin; 
}

#iconheader{
  height: fit-content;
  width: fit-content; 
  align-items: center;
  margin-top: 20px;
  margin-left: 20px;
}

nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 30px;
}

nav a {
  margin: -2% 20%;
  color: #ffffff;
  text-decoration: none;
  font-size: medium;
  font-family: 'Poppins', sans-serif;
  margin-top: 13%;
  color: #908e98;
}

nav a::after {
  content: "";
  display: block;
  width: 0%;
  height: 2px;
  background-color: #ffffff;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

nav a:hover::after,
nav a.active::after {
  transform: scaleX(0.8);
  width: 100%;
}

nav a.active {
  transform: translateY(-2px);
  color: #bbbac0;
}

section{
  align-items: center;
  z-index: 100;
  user-select: none;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background:#1a224c;
  color:#ffffff;
  font-size: 100px;
  font-family: 'Poppins', sans-serif;
  font-weight: 800;
}

.text-box{
  margin-top: 15%;
}

.text-wrapper{
  box-sizing: content-box;
  background-color: transparent;
  align-content: center;
  height: 30px;
  display: flex;
  border-radius: 8px;
  margin-left: -70%;
  margin-top: -25%;
  margin-bottom: -10%;
}

.words{
  overflow: hidden;
}

.word{
  display: block;
  height: 100%;
  padding-left: 10px;
  color: #ffffff;
  animation: spin_words 6s infinite;
  font-family: 'Rubik', sans-serif;
  font-size: 20px;
  font-weight: 900;
}

@keyframes spin_words{
  10%{
      transform: translateY(-112%);
  }
  25%{
      transform: translateY(-100%);
  }
  35%{
      transform: translateY(-212%);
  }
  50%{
      transform: translateY(-200%);
  }
  60%{
      transform: translateY(-312%);
  }
  75%{
      transform: translateY(-300%);
  }
  85%{
      transform: translateY(-412%);
  }
  100%{
      transform: translateY(-400%);
  }
}

.desc p2{
  font-family: 'Source Code Pro', monospace;
  font-size: 20px;
  font-weight: 600;
  font-weight: -10;
  z-index: 10;
}

.desc {
  position: relative;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  margin-top: 2vh;
}

.text-box{
  width: 100%;
}

.text-box h1{
  font-size: 70px;
  font-weight: 900;
  text-align: left;
  margin-left: -70%;
}

.text-box h2{
  font-size: 70px;
  font-weight: bolder;
  display: flex;
  align-items: center;
  text-align: left;
  margin-top: -40%;
  margin-left: 5%;
  margin-bottom: 0%;
}

.text-box h3 {
  font-size: 70px;
  color: transparent;
  -webkit-text-stroke: 1px white;
  background: url('../../public/images/binarybg.png');
  background-size: contain;
  -webkit-background-clip: text;
  background-position: 0 0;
  animation: back 20s linear infinite;
}

section#profile{
  display: flex;
  flex-direction: column;
  background: linear-gradient(#141d26, #353C51, #353C51);
  height: fit-content;
}

.about{
  position: relative;
  display: flex;
  align-items: center;
  height: 100vh;
  background-color: #353C51;
}

.about img{
  width: 45vw;
  height: auto;
  border-radius: 10px;
  margin-left: 30vw;
  min-width: 300px;
}

section#portfolio{
  display: flex;
  flex-direction: column;
  background: linear-gradient(#353C51, #2b3c4e);
  height: fit-content;
}

section#contact{
  display: flex;
  flex-direction: column;
  background: linear-gradient(#2b3c4e, #273b50);
  height: fit-content;
}

#jump {
  display: flex;
  position: relative;
  flex-direction: row;
  z-index: 100;
  gap: 10%;
  width: 100vw;
  bottom: 3vh;
  justify-content: center;
  margin-top: -7%;
}

#jump a {
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
  color: white;
  font-size: 15px;
  font-weight: 100;
  padding: 10px 10px;
  border: 2px solid white;
  margin-top: 10%;
  width: fit-content;
}

#jump a:hover {
  background-color: white;
  color: #1a224c;
}

#jump a:hover span {
  color: #1a224c;
}

#icons{
  display: flex;
  position: fixed;
  flex-direction: column;
  z-index: 10000;
  gap: 10px;
  bottom: 100px;
  left: 10px;
}

#icons img, #icons a{
  width: 24px;
  height: 24px;
}

#icons .linkedin{
  width: 23px;
  height: 23px;
  left: 1px;
}

@keyframes back {
  100%{
    background-position: 0px 1000px;
  }
}

.text-box a{
  font-weight: 100;
  font-size: 15px;
  color: #ffffff;
  padding: 10px 20px;
  text-decoration: none;
  border:  2px solid white;
  align-items: center;
  margin-left: -100%;
}

.text-box a span{
  font-size: 20px;
  line-height: 10px;
  margin-left: 5px;
  color: white;
}

.text-box a:hover {
  background-color: white;
  color: #1a224c;
}

.text-box a:hover span {
  color: #1a224c
}

#tilt {
  position: relative;
  margin-left: 10%;
  margin-top: 22vh;
  z-index: 9;
  display: block;
  height: 300px;
  width: 300px;
  background-color: transparent;
  border-radius: 0px;
  background-image: url('../../public/images/pixelsam.png');
  background-size: 65%;
  background-repeat: no-repeat;
  animation: fade-in-up 1s timing-function 0.5s iteration-count direction forwards;
}

@keyframes fade-in-up {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.copyright{
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 10px;
  position: relative;
  margin-top: 30px;
  bottom: 0;
}

.copyright span{
  position: absolute;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  font-weight: 100;
  color: #afafaf;
  right: 20px;
  bottom: 10px;
}

.next{
  margin-top: 50px;
  position: relative;
  width: 100vw;
  height: fit-content;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.next span{
  font-family: 'Instrument Sans', sans-serif;
  font-size: 25px;
}

.next img{
  position: absolute;
  margin-top: -9%;
}

.currentwork{
  margin-top: 350px;
  height: fit-content;
  position: relative;
  align-items: center;
}

.currentwork .fade p{
  font-family: 'Instrument Sans', sans-serif;
  font-size: 23px;
  margin-top: -30px;
  position: relative;
  margin-left: 140px;
}

.currentwork .fade #head{
  width: 600px;
  height: 70px;
  position: relative;
}

.next #head{
  width: 500px;
  height: 60px;
}

.subheading p3{
  font-family: 'Instrument Sans', sans-serif;
  font-size: 25px;
  z-index: 1000;
  position: absolute;
  margin-left: -77%;
  margin-top: 20%;
}

#contactdesc{
  margin-top: 30px;
  font-size: 13px;
  font-family: 'Poppins', sans-serif;
  font-weight: 100;
  color: #454545;
  opacity: 70%;
}

.map iframe{
  margin-left: 0px;
  margin-top: 30px;
  height: 120px;
  width: 220px;
  border-radius: 10px;
  opacity: 0.5;
}

.map iframe:hover{
  opacity: 1;
}

#head{
  width: 550px;
  height: 65px;
  z-index: 1;
  margin-bottom: -9%;
}

*, *:before, *:after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  letter-spacing: 1.4px;
}

.background {
  margin-top: 20px;
  display: flex;
  min-height: 100vh;
  caret-color: #77afe8;
}

.contactinner {
  flex: 0 1 700px;
  margin: auto;
  padding: 10px;
}

.screen {
  position: relative;
  background: #b8b7b7;
  border-radius: 15px;
  width: 70vw;
  height: 500px;
}

.contact-icons{
  position: absolute;
  top: 200px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.contact-icons img{
  width: 20px;
  height: 20px;
}

.screen-header {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background: #4d4d4f;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  height: 40px;
}

.screen-header-left {
  margin-right: auto;
  margin-top: -60px;
}

.screen-header-button {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 3px;
  border-radius: 8px;
  background: white;
}

.screen-header-button.close {
  background: #ed1c6f;
}

.screen-header-button.maximize {
  background: #e8e925;
}

.screen-header-button.minimize {
  background: #74c54f;
}

.screen-header-right {
  display: flex;
}

.screen-header-ellipsis {
  width: 3px;
  height: 3px;
  margin-left: 2px;
  border-radius: 8px;
  background: #999;
}

.screen-body {
  display: flex;
}

.screen-body-item {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 50px;
  height: 500px;
  margin-top: -100px;
}

.screen-body-item.left {
  display: flex;
  flex-direction: column;
  margin-top: -30px;
}

.app-title {
  display: flex;
  flex-direction: column;
  position: relative;
  color: #ffffff;
  font-size: 26px;
}

.app-title:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 25px;
  height: 4px;
  background: #3b5167;
}

.app-contact {
  margin-top: auto;
  font-size: 12px;
  font-weight: 600;
  color: #888;
  margin-left: 30px;
  margin-top: 30px;
}

.app-contact a{
  text-decoration: none;
  font-size: 12px;
  font-weight: 600;
  color: #888;
}

.slide-container {
  width: 20vw;
  height: 20vh;
  display: flex;
  align-items: center;
  overflow: hidden;
}

#slider-span1, #slider-span2, #slider-span3, #slider-span4, #slider-span5{
  margin-top: -150%;
}

.image-slider{
  margin-top: 80%;
  margin-left: -30%;
  width: 100%;
  height: 600px;
  position: absolute;
  left: -2%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 1s;
}

.slides-div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 30px;
}

.img{
  position: relative;
  width: 150px;
  height: 175px;
  transition: 0.5s;
}

.button {
  position: absolute;
  width: 150px;
  height: 175px;
  transition: 0.5s;
}

.button a , .p4{
  position: absolute;
  width: 136px;
  height: 25px;
  top: 145px;
  margin-left: 5%;
  border-radius: 20px;
  transition: 0.5s;
  background: linear-gradient(to right, #455189, #777fa4);
  font-size: 15px;
  text-decoration: none;
}

.view-project p4{
  font-family: 'Poppins', sans-serif;
  color: white;
  font-weight: 100;
  margin-left: 40px;
  transition: 0.5s; 
}

.box{
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.name{
  position: relative;
}

.name img{
  position: absolute;
  top: 105px;
  left: 25px;
  transform: translateY(-50%);
  width: 15px;
  height: auto;
}

.name input{
  width: 90%;
  height: 20px;
  padding: 20px 60px;
  border: none;
  outline: none;
  font-size: 15px;
  background-color: #eee;
  border-radius: 20px;
}

.name input::placeholder{
  font-weight: 500;
  font-size: 13px;
  color: #999;
}

.email{
  width: 100%;
  height: 100px;
  position: relative;
}

.email img{
  z-index: 10;
  position: absolute;
  top: 21px;
  left: 24px;
  transform: translateY(-50%);
  width: 18px;
  height: auto;
  color: #bbb;
}

.email input{
  position: absolute;
  width: 90%;
  height: 20px;
  padding: 20px 60px;
  border: none;
  outline: none;
  font-size: 15px;
  background-color: #eee;
  border-radius: 20px;
}

.email input::placeholder{
  color: #999;
  font-weight: 500;
  font-size: 13px;
}

.message-box{
  width: 100%;
  position: relative;
  margin-bottom: 15px;
}

.message-box img{
  position: absolute;
  top: 50%;
  left: 30px;
  transform: translateY(-50%);
  font-size: 20px;
  color: #999;
}

.message-box textarea{
  width: 90%;
  height: 200px;
  padding: 20px 30px;
  border: none;
  outline: none;
  font-size: 15px;
  background-color: #eee;
  border-radius: 20px;
  resize: none;
}

.message-box textarea::placeholder{
  color: #999;
  font-weight: 500;
  font-size: 15px;
}

.message{
  width: 100%;
  position: relative;
  margin-bottom: 60px;
  display: flex;
  justify-content: center;
}

.sendbutton{
  position: relative;
  margin-top: -115px;
  left: 70%;
}

.send {
	position: relative;
	display: flex;
	align-items: center;
	color: #4d4d4f ;
	background: transparent;
	border: none;
	font-family: inherit;
	border-radius: 3rem;
	font-size: 15px;
	font-variation-settings: 'wght' 500;
	cursor: pointer;
	transition: transform 0.4s ease-out;
  top: 60px;
  left: -50px;
	&:hover {
		.icon svg {
			transform: translate(-5px, 5px);
		}
    color: #656565;
	}
	box-shadow: 9px 9px 16px 0px #a3b1c6, -9px -9px 16px 0px rgba(#fff, 0.6);
}

.text {
	height: 4.5rem;
padding: 1rem 1rem 1rem 2rem;
}

.icon {
	display: block;
	padding: 1rem;
	box-shadow: 9px 9px 16px 0px #a3b1c6, -9px -9px 16px 0px rgba(#fff, 0.6);
	border-radius: 50%;
	width: 3.5rem;
	height: 3.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
  margin-left: -20px;
  margin-top: -20px;
}

svg {
	fill: currentColor;
	width: 2rem;
	height: 2rem;
	transition: transform 0.4s ease-out;
}

.currentwork{
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 400px;
}

.bubbles{
  display: flex;
  flex-direction: column;
  gap: 100px;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 100px;
  perspective: 1px;
}

.bubbles img{
  width: auto;
  height: 15vh;
}

#bubble1, #bubble3{
  margin-left: 150px;
  perspective: 100px;
  z-index: 10;
  animation: moveUp 10s infinite linear;
}

#bubble2, #bubble4{
  margin-left: -150px;
  z-index: 100;
  animation: moveUp 15s infinite linear;
}

#slider-span1:target ~ .image-slider #img1,
#slider-span1:target ~.image-slider #button-1,
#slider-span2:target ~ .image-slider #img2,
#slider-span2:target ~.image-slider #button-2,
#slider-span3:target ~ .image-slider #img3,
#slider-span3:target ~.image-slider #button-3,
#slider-span4:target ~ .image-slider #img4,
#slider-span4:target ~.image-slider #button-4,
#slider-span5:target ~ .image-slider #img5,
#slider-span5:target ~.image-slider #button-5{
  width: 300px;
  height: 350px;
  background-color: transparent;
}

#slider-span1:target ~.image-slider #view-button, 
#slider-span2:target ~.image-slider #view-button2,
#slider-span3:target ~.image-slider #view-button3,
#slider-span4:target ~.image-slider #view-button4,
#slider-span5:target ~.image-slider #view-button5{
  position: absolute;
  width: 273px;
  height: 45px;
  top: 295px;
  margin-left: 5%;
  background: linear-gradient(to right, #596cc5, #a5b1e5);
}

#slider-span1:target ~.image-slider #view-text, 
#slider-span2:target ~.image-slider #view-text2,
#slider-span3:target ~.image-slider #view-text3,
#slider-span4:target ~.image-slider #view-text4,
#slider-span5:target ~.image-slider #view-text5{
  position: absolute;
  margin-left: 100px;
  margin-top: 6px;
  font-size: 20px;
}

.view-project p4:hover{
  color: #2b3c4e;
}

#slider-span1:target ~.image-slider{
  left:105%;
}
#slider-span2:target ~.image-slider{
  left:65%;
}
#slider-span3:target ~.image-slider{
  left:28%;
}

#slider-span4:target ~.image-slider{
  left: -10%;
}

#slider-span5:target ~.image-slider{
  left: -45%;
}

::-webkit-scrollbar{
  color: transparent;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb{
  background:linear-gradient(transparent, #a0b9d2, #3b5167);
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover{
  background:linear-gradient(transparent, #859eb6, #2b3c4e);
  border-radius: 6px;
}

@media screen and (max-width: 1120px) {
  .app-contact{
    margin-top: 10px;
  }
}

@media screen and (max-width: 850px) {
  .app-title span{
    font-size: 15px;
  }

  #contactdesc{
    width: 96%;
  }

  .sendbutton{
    left: 50%;
  }
  
  .send{
    font-size: 13px;
  }

  .screen-body-item.left{
    margin-left: -10px;
  }

  .screen-body-item form{
    width: 120%;
  }

  .screen-body-item{
    margin-left: -70px;
  }

  .app-contact{
    font-size: 11.5px;
    margin-top: 17px;
  }

  .contact-icons{
    margin-top: -15px;
  }
}

@media screen and (max-width: 700px) {
  #slider-span1:target ~ .image-slider #img1,
  #slider-span1:target ~.image-slider #button-1,
  #slider-span2:target ~ .image-slider #img2,
  #slider-span2:target ~.image-slider #button-2,
  #slider-span3:target ~ .image-slider #img3,
  #slider-span3:target ~.image-slider #button-3,
  #slider-span4:target ~ .image-slider #img4,
  #slider-span4:target ~.image-slider #button-4,
  #slider-span5:target ~ .image-slider #img5,
  #slider-span5:target ~.image-slider #button-5{
    width: 250px;
    height: 300px;
    background-color: transparent;
  }

  .img{
    width: 100px;
    height: 125px;
  }
  
  .button {
    width: 100px;
    height: 125px;
  }

  .image-slider{
    width: 50%;
    height: 500px;
  }

  #slider-span1, #slider-span2, #slider-span3, #slider-span4, #slider-span5{
    margin-top: -450%;
  }

  #slider-span1:target ~.image-slider{
    left:95%;
  }

  #slider-span2:target ~.image-slider{
    left:75%;
  }
  #slider-span3:target ~.image-slider{
    left:55%;
  }
  
  #slider-span4:target ~.image-slider{
    left: 35%;
  }
  
  #slider-span5:target ~.image-slider{
    left: 15%;
  }

  .button a , .p4{
    width: 90px;
    height: 18px;
    top: 105px;
    margin-left: 5%;
    font-size: 10px;
  }

  #slider-span1:target ~.image-slider #view-button,
  #slider-span2:target ~.image-slider #view-button2,
  #slider-span3:target ~.image-slider #view-button3,
  #slider-span4:target ~.image-slider #view-button4,
  #slider-span5:target ~.image-slider #view-button5{
    position: absolute;
    width: 223px;
    height: 40px;
    top: 250px;
    margin-left: 5%;
    background: linear-gradient(to right, #596cc5, #a5b1e5);
  }

  #slider-span1:target ~.image-slider #view-text,
  #slider-span2:target ~.image-slider #view-text2,
  #slider-span3:target ~.image-slider #view-text3,
  #slider-span4:target ~.image-slider #view-text4,
  #slider-span5:target ~.image-slider #view-text5{
    margin-left: 35%;
  }

  #view-text, #view-text2, #view-text3, #view-text4, #view-text5{
    margin-left: 30%;
  }

  .slides-div {
    margin: 0 5px;
  }

  #slider-span1, #slider-span2, #slider-span3, #slider-span4, #slider-span5{
    margin-top: -450%;
  }

  .screen-body{
    flex-direction: column;
  }

  .screen{
    height: fit-content;
  }

  .screen-body-item{
    margin-top: -180px;
    margin-left: 10px;
  }

  .screen-body-item.left{
    margin-left: 1%;
  }

  .sendbutton{
    margin-left: -15%;
  }

  .text-box h1,.text-box h2,.text-box h3{
    font-size: 70px;
  }

  #contactdesc{
    font-size: 12.9px;
  }

  .contact-icons{
    margin-top: -33px;
  }
}

@media screen and (max-width: 680px) {
  #tilt{
    width: 40%;
    height: 40%;
  }

  .word{
    font-size: 15px;
  }

  .text-wrapper{
    height: 20px;
  }
  .text-box h1,.text-box h2,.text-box h3{
    font-size: 60px;
  }

  .desc p2{
    font-size: 15px;
  }
}

@media screen and (max-width: 600px) {
  .text-box h1,.text-box h2,.text-box h3{
    font-size: 55px;
  }

  #icons{
    position: absolute;
    flex-direction: row;
    left: 45vw;
  }

  #icons img, #icons a{
    width: 16px;
    height: 16px;
  }

  #icons .github{
    width: 17px;
    height: 17px;
    margin-left: 0px;
  }

  #icons .linkedin{
    width: 16px;
    height: 16px;
    margin-left: 0px;
  }

  .bubbles img{
    width: auto;
    height: 10vh;
  }
}

@media screen and (max-width: 580px) {
  .contact-icons{
    margin-top: -15px;
  } 
}

@media screen and (max-width: 550px) {
  .text-box h1,.text-box h2,.text-box h3{
    font-size: 48px;
  }

  .scrollcontainer{
    margin-left: 15px;
    width: 105vw;
  }

  .about img{
    width: 80vw;
    height: auto;
    margin-left: 10vw;
  }  
}

@media screen and (max-width: 490px) {
  .text-box h1,.text-box h2,.text-box h3{
    font-size: 45px;
  }
  
  .header-nav a{
    font-size: 10px;
  }

  .desc{
    margin-bottom: 10%;
  }

  #jump a{
    font-size: 10px;
  }
}

@media screen and (max-width: 456px) {
  .contact-icons{
    margin-top: 10px;
  }

  .text-box h1,.text-box h2,.text-box h3{
    font-size: 40px;
  }

  .bubbles img{
    width: auto;
    height: 10vh;
  }

  .bubbles{
    gap: 50px;
  }

  #bubble1, #bubble3{
    margin-left: 100px;
  }
  
  #bubble2, #bubble4{
    margin-left: -100px;
  }
}

@media screen and (max-width: 430px) {
  .app-title span{
    font-size: 18px;
  }

  #contactdesc{
    font-size: 10px;
  }

  .app-contact{
    position: absolute;
    font-size: 8px;
    margin-top: 140px;
    margin-left: 20px;
  }

  .app-contact a{
    font-size: 8px;
  }

  .contact-icons img{
    width: 10%;
    height: 10%;
  }

  .contact-icons{
    gap: 10px;
    width: 120px;
    height: auto;
    margin-top: 0px;
  }

  .map iframe{
    width: 100%;
    height: auto;
    margin-top: 120px;
  }

  .email img{
    width: 10%;
    height: auto;
  }

  .name img{
    width: 9%;
    height: auto;
  }

  .name input::placeholder, .email input::placeholder, .message-box textarea::placeholder{
    font-size: 10px;
  }
}

@media screen and (max-width: 400px) {
  .subheading #head{
    width: 300px;
    height: auto;
  }
  
  .subheading p3{
    font-size: 14px;
    margin-left: -235px;
    margin-top: 110px;
  }

  .fade span{
    font-size: 14px;
  }

  .fade #head{
    width: 300px;
    height: auto;
  }

  .currentwork .fade p{
    font-size: 14px;
    margin-left: 102px;
  }

  .currentwork .fade #head{
    width: 400px;
    height: auto;
  }

  .text-box h1,.text-box h2,.text-box h3{
    font-size: 38px;
  }

  .desc{
    margin-bottom: 10%;
  }

  #slider-span1:target ~.image-slider{
    left:120%;
  }

  #slider-span2:target ~.image-slider{
    left:85%;
  }

  #slider-span4:target ~.image-slider{
    left: 20%;
  }
  
  #slider-span5:target ~.image-slider{
    left: 0%;
  }

  #slider-span1, #slider-span2, #slider-span3, #slider-span4, #slider-span5{
    margin-top: -800%;
  }
}

@media screen and (max-width: 388px) {
  .text-box h1,.text-box h2,.text-box h3{
    font-size: 33px;
  }

  .desc p2{
    font-size: 13px;
  }

  #tilt{
    margin-top: 30vh;
  }
}

@media screen and (max-width: 340px) {
  .text-box p2{
    font-size: 13px;
  }

  .text-box h1,.text-box h2,.text-box h3{
    font-size: 30px;
  }

  #tilt{
    margin-top: 50vw;
    margin-left: 10%;
  }

  #jump a{
    font-size: 9px;
  }

  .words span{
    font-size: 10px;
  }

  .about img{
    width: 80vw;
    height: auto;
    margin-left: 5vw;
  }  
}